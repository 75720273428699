import {Model, MyassaysComponent, PropTypes, Utils} from 'myassays-global';
import flagSymbol from './media/flag.svg?raw';

const flagSymbolUrl = Utils.makeDataUrl(flagSymbol);

const template = props => `\
<style>
    :host {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow: hidden;
    }
    #chart-group {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
    }
    #chart-description {
        flex-grow: 0;
        flex-basis: content;
        overflow: hidden;
        border: 1px solid gray;
        border-radius: 0.5em;
        padding: 5px;
        margin: 10px;
    }
    #chart-description:empty {
        display: none;
    }
    #chart-root {
        flex-grow: 1;
        overflow: hidden;
        position: relative;
    }
    #subset-selector {
        flex-grow: 0;
        flex-basis: content;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .tag-key {
        position: sticky;
        top: 0;
        background-color: inherit;
        margin: 0;
        padding: 4px;
    }
    
    #subset-selector div.form-check {
        white-space: nowrap;
    }
    .highcharts-markers image {
        pointer-events: visible;
        transform: translate(-8px, -35px) !important;
    }
    .highcharts-markers image[stroke] {
        width: 33px !important;
        height: 38px !important;
        transform: translate(-9px, -38px) !important;
        filter: drop-shadow(0 0 5px rgba(0, 0, 128, 0.5));
 }
</style>
<div id="chart-group" part="chart-group">
    <div id="chart-root" part="chart"></div>
    <div id="chart-description" part="description"></div>
</div>
<div id="subset-selector" part="subset-selector"></div>
`

const subsetSelectorTemplate = chartData => {
    const kineticSeries = chartData.data.series.filter(item => item.type === 'Kinetic');
    return `\
<p class="tag-key">${chartData.tagKey}</p>
${kineticSeries.map((series, i) => `\
<div class="form-check">
    <input class="form-check-input" type="${chartData.tagSelection === 'Single' ? 'radio' : 'checkbox'}" name="tag-selection" id="${series.tags}" ${i > 0 ? '' : 'checked'}/>
    <label class="form-check-label" for="${series.tags}"> ${series.tags}</label>
</div>`).join('')}
`;
}

export default class MyassaysInteractiveChart extends MyassaysComponent {
    static get propTypes() {
        return {
            id: PropTypes.string.required,
            chartDataJson: PropTypes.json.default(null).observed,
            chartDataUrl: PropTypes.string.default(null).observed,
            analysisControlDataUrl: PropTypes.string.default(null).observed,
            flaggingEnabled: PropTypes.bool.default(true).observed,
        }
    }

    static get observedAttributes() {
        return PropTypes.getObserved(this.propTypes);
    }

    constructor() {
        super();

        this._state = new Model({
            chartData: null,
            chartDataJson: null,
            chartDataUrl: null,
            analysisControlDataUrl: null,
        });

        this._root = this.attachShadow({mode: 'open'});
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (this._hasBeenRendered) {
            if (attrName === 'chart-data-url' || attrName === 'chart-data-json') {
                this._root.getElementById('subset-selector').innerHTML = '';
                this._setDescription();
            }

            this._state.set(PropTypes.attributesToProps(this, attrName));
        }
    }

    connectedCallback() {
        this._props = PropTypes.attributesToProps(this);
        this._root.innerHTML = template(this._props);
        this._hasBeenRendered = true;
        this._state.addEventListener('change', evt => this._onStateChange(evt));
        this._state.set(this._props);
        this._enclosingPane = this.parentElement;
        while (this._enclosingPane.tagName.toLowerCase() !== 'mp-pane') {
            if (this._enclosingPane === document.body) {
                this._enclosingPane = null;
                break;
            }
            this._enclosingPane = this._enclosingPane.parentElement;
        }
        if (this._enclosingPane) {
            this._enclosingPane.addEventListener('paneresize', this._onPaneResize);
        }
    }

    disconnectedCallback() {
        delete this._chart;
        if (this._enclosingPane) {
            this._enclosingPane.removeEventListener('paneresize', this._onPaneResize);
        }
    }

    _onPaneResize = evt => {
        this.reflow();
    }

    _onStateChange(evt) {
        const { hasChanged } = evt.data;
        hasChanged('chartData', newValue => {
            newValue && this._renderChart(newValue);
        });
        hasChanged('chartDataJson', newValue => {
            if (newValue) {
                this._state.set({
                    chartDataUrl: null,
                    analysisControlDataUrl: null,
                    chartData: newValue,
                });
            } else {
                this.removeAttribute('chart-data-json');
            }
        });
        hasChanged('chartDataUrl', newValue => {
            if (newValue) {
                this._state.set({
                    chartDataJson: null,
                    analysisControlDataUrl: null,
                });
                fetch(newValue).then(response => {
                    response.json().then(json => {
                        this._state.set({chartData: json});
                    });
                });
            } else {
                this.removeAttribute('chart-data-url');
            }
        });
        hasChanged('analysisControlDataUrl', newValue => {
            if (newValue) {
                this._state.set({
                    chartDataJson: null,
                    chartDataUrl: null,
                });
                fetch(newValue).then(response => {
                    response.text().then(text => {
                        this._populateFromAnalysisControlXml(text);
                    });
                });
            } else {
                this.removeAttribute('analysis-control-data-url');
            }
        });
    }

    _renderChart(chartData) {
        if (Highcharts) {
            if (Highcharts.version !== '4.2.5') {
                console.log(`WARNING! Highcharts version ${Highcharts.version} detected, this may not be compatible with the "myassays-interactive-chart" component which requires version 4.2.5.`);
            }
            this._chart = Highcharts.chart(this._root.querySelector('#chart-root'), chartData);
            this._renderFlags();
            const event = new CustomEvent('chartdatachange', {
                bubbles: true,
            });
            this.dispatchEvent(event);
        } else {
            setTimeout(() => this._renderChart(chartData), 100);
        }
    }

    reflow() {
        this._chart && this._chart.reflow();
    }

    populateFromJson(dataJson) {
        this._root.getElementById('subset-selector').innerHTML = '';
        this._setDescription();
        this._state.set({
            chartDataJson: null,
            chartDataUrl: null,
            analysisControlDataUrl: null,
            chartData: dataJson,
        });
    }

    populateFromAnalysisControlXml(dataXml) {
        this._state.set({
            chartDataJson: null,
            chartDataUrl: null,
            analysisControlDataUrl: null,
        });
        this._populateFromAnalysisControlXml(dataXml);
    }

    _setDescription(text) {
        this._root.getElementById('chart-description').innerHTML = text || '';
    }

    _populateFromAnalysisControlXml(dataXml) {
        const styleList = getComputedStyle(this);
        const cssVar = name => styleList.getPropertyValue(name);

        const data = Utils.xmlToObject(dataXml, {
            arrays: ['Axis', 'Series', 'Point', 'Content'],
            booleans: ['KineticSubsetSelection', 'Hidden', 'AllowFlagging', 'Flagged', 'LegendShow'],
            numbers: ['X', 'Y', 'Min', 'Max', 'ContainerIndex', 'MatrixIndex', 'PointIndex'],
        }).analysisControl.chart;

        this._pointData = {};
        this._seriesData = {};

        this._root.getElementById('subset-selector').innerHTML = data.tagSelection ? subsetSelectorTemplate(data) : '';
        this._setDescription();

        if (data.tagSelection) {
            if (data.tagSelection === 'Single') {
                this._descriptions = {};
                data.description && data.description.content && data.description.content.forEach(content => {
                    this._descriptions[content.tag] = content.report.text['#text'];
                });
            }
            Array.from(this._root.querySelectorAll('#subset-selector input')).forEach((input, i, allInputs) => {
                if (i === 0) {
                    this._firstSubsetTag = input.id;
                    if (data.tagSelection === 'Single') {
                        this._setDescription(this._descriptions[input.id]);
                    }
                }
                input.__wasChecked = input.checked;
                input.addEventListener('change', evt => {
                    allInputs.forEach(item => {
                        if (item.checked !== item.__wasChecked) {
                            const k = this._chart.get(`kinetic-${item.id}`);
                            k && k.setVisible(item.checked);
                            const s = this._chart.get(`slope-${item.id}`);
                            s && s.setVisible(item.checked);
                            item.__wasChecked = item.checked;
                        }
                        if (data.tagSelection === 'Single') {
                            item.checked && this._setDescription(this._descriptions[item.id]);
                        }
                    });
                });
            });
        }

        const xAxisData = data.axis.find(item => item.id === 'X');
        const yAxisData = data.axis.find(item => item.id === 'Y');
        const plottableSeries = data.data.series.filter(item => ['InputPoints', 'Calculated', 'FitLine', 'Kinetic'].includes(item.type) || (item.type === undefined && item.tags !== undefined));

        function getColor(color) {
            return color.replace(/^#[0-9A-F]{2}([0-9A-F]{6})$/i, '#$1');
        }

        function pointOkOnLogAxis(point) {
            return (point.x > 0 || xAxisData.type !== 'Log') && (point.y > 0 || yAxisData.type !== 'Log');
        }

        const getMarker = series => {
            const appearance = series.appearance || {};
            const marker = appearance.marker ? appearance.marker.toLowerCase() : 'circle';
            const markerColour = appearance.markerColour || 'red';
            return {
                enabled: marker !== 'none',
                fillColor: getColor(markerColour),
                symbol: marker,
                lineColor: 'grey',
                lineWidth: getColor(markerColour) === '#FFFFFF' ? 1 : 0,
            }
        }

        const inputPointsSeries = series => {
            const seriesId = series.name;
            this._seriesData[seriesId] = {
                allowFlagging: series.allowFlagging !== false,
                matrixIndex: series.matrixIndex,
                pointsHaveIds: true,
            }
            return {
                id: seriesId,
                type: 'scatter',
                animation: false,
                name: series.name,
                visible: !series.hidden,
                enableMouseTracking: series.allowFlagging !== false,
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.name}',
                },
                point: {
                    events: series.allowFlagging === false ? {} : {
                        click: this._onFlaggablePointClick,
                    }
                },
                marker: getMarker(series),
                data: series.point.filter(pointOkOnLogAxis).map(point => {
                    const pointId = `${series.name}-${point.id}`;
                    this._pointData[pointId] = {
                        id: pointId,
                        seriesId,
                        flagState: point.flagged,
                        detail: {
                            pointId: point.id,
                            seriesName: series.name,
                            label: point.label,
                            x: point.x,
                            y: point.y,
                            pointIndex: point.pointIndex,
                            containerIndex: point.containerIndex,
                            matrixIndex: series.matrixIndex,
                        }
                    };
                    return {
                        id: pointId,
                        x: point.x,
                        y: point.y,
                        name: point.label,
                    }
                }),
                events: {
                    show: this._onSeriesVisibilityChange,
                    hide: this._onSeriesVisibilityChange,
                }
            };
        }

        const fitLineSeries = series => {
            return {
                id: series.name,
                type: 'spline',
                animation: false,
                name: series.name,
                visible: !series.hidden,
                color: cssVar('--ma-fit-line-color') || '#000000',
                marker: {
                    enabled: false,
                },
                enableMouseTracking: false,
                data: series.point.map(point => ({
                    x: point.x,
                    y: point.y,
                })),
            }
        }

        const calcCurveSeries = series => {
            const numOfPoints = 100;
            const calculatedPoints = [];
            const { equation } = series;
            for (const [key, value] of Object.entries(equation)) {
                if (key.length === 1) {
                    equation[key] = Number(value);
                }
            }
            let minX = xAxisData.min;
            let maxX = xAxisData.max;
            if (minX === undefined || maxX === undefined) {
                const standardSeries = plottableSeries.find(item => item.name === 'Standard');
                const referenceSeries = standardSeries ? [standardSeries] : plottableSeries.filter(item => item.type === 'InputPoints');
                const referencePoints = [].concat(...referenceSeries.map(item => item.point.filter(pointOkOnLogAxis).map(point => point.x)));
                minX = Math.min(...referencePoints);
                maxX = Math.max(...referencePoints);
            }
            const xAxisLog = xAxisData.type === 'Log';

            function calcY(x) {
                switch (equation.type) {
                    case '4PL': {
                        const {a, b, c, d} = equation;
                        return d + ((a - d) / (1 + Math.pow(x / c, b)));
                    }
                    case '5PL': {
                        const {a, b, c, d, m} = equation;
                        return d + ((a - d) / Math.pow(1 + Math.pow(x / c, b), m));
                    }
                    case 'Linear Regression': {
                        const {c, m} = equation;
                        return m * x + c;
                    }
                }
            }

            if (xAxisLog) {
                const minLog = Math.log10(minX);
                const maxLog = Math.log10(maxX);
                const inc = (maxLog - minLog) / (numOfPoints - 1);
                for (let i = 0; i < numOfPoints; i++) {
                    const x = Math.pow(10, minLog + i * inc);
                    calculatedPoints.push({x, y: calcY(x)});
                }
            } else {
                const inc = (maxX - minX) / (numOfPoints - 1);
                for (let i = 0; i < numOfPoints; i++) {
                    const x = minX + i * inc;
                    calculatedPoints.push({x, y: calcY(x)});
                }
            }

            const fitLineColor = (!series.appearance || series.appearance.lineColour === undefined) ? (cssVar('--ma-fit-line-color') || '#000000') : getColor(series.appearance.lineColour);

            return {
                id: series.name,
                type: 'spline',
                animation: false,
                name: series.name,
                visible: !series.hidden,
                color: fitLineColor,
                marker: {
                    enabled: false,
                },
                enableMouseTracking: false,
                data: calculatedPoints,
            }
        }

        const kineticSeries = series => {
            const seriesId = `kinetic-${series.tags}`;
            this._seriesData[seriesId] = {
                allowFlagging: series.allowFlagging !== false,
            }
            return {
                id: seriesId,
                type: 'scatter',
                lineWidth: 2,
                animation: false,
                name: series.name,
                visible: series.tags === this._firstSubsetTag,
                color: !series.appearance || series.appearance.lineColour === undefined ? '#000000' : getColor(series.appearance.lineColour),
                enableMouseTracking: series.allowFlagging !== false,
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.name}',
                },
                point: {
                    events: series.allowFlagging === false ? {} : {
                        click: this._onFlaggablePointClick,
                    }
                },
                marker: getMarker(series),
                data: series.point.filter(pointOkOnLogAxis).map(point => {
                    const pointId = `${seriesId}-${point.pointIndex}`;
                    const pointName = `${series.name}, point ${point.pointIndex} (x: ${point.x}, y: ${point.y})`;
                    this._pointData[pointId] = {
                        id: pointId,
                        seriesId,
                        flagState: point.flagged,
                        detail: {
                            pointId: point.id,
                            pointIndex: point.pointIndex,
                            containerIndex: series.containerIndex,
                            matrixIndex: series.matrixIndex,
                            seriesName: series.name,
                            label: pointName,
                            x: point.x,
                            y: point.y,
                        }
                    };
                    return {
                        id: pointId,
                        x: point.x,
                        y: point.y,
                        name: pointName,
                    }
                }),
                events: {
                    show: this._onSeriesVisibilityChange,
                    hide: this._onSeriesVisibilityChange,
                }
            };
        }

        const kineticMaxSlopeSeries = series => {
            const seriesId = `slope-${series.tags}`;
            return {
                id: seriesId,
                type: 'line',
                animation: false,
                name: series.name,
                visible: series.tags === this._firstSubsetTag,
                color: !series.appearance || series.appearance.lineColour === undefined ? '#000000' : getColor(series.appearance.lineColour),
                enableMouseTracking: false,
                marker: getMarker(series),
                data: series.point.filter(pointOkOnLogAxis).map(point => ({
                    x: point.x,
                    y: point.y,
                })),
            };
        }

        const getFlagSeries = () => {
            return {
                id: 'Flags',
                type: 'scatter',
                animation: false,
                name: 'Flags',
                showInLegend: false,
                marker: {
                    enabled: true,
                    symbol: `url(${flagSymbolUrl})`,
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.name}',
                },
                point: {
                    events: {
                        click: this._onFlaggablePointClick,
                    },
                },
                data: [],
            };
        }

        function getLegend(data) {
            if (data.legendShow === false && data.customizableChartType !== 'DoseResponseCurves') {
                return {
                    enabled: false,
                };
            }
            const common = {
                backgroundColor: cssVar('--ma-legend-background-color'),
                borderColor: cssVar('--ma-legend-border-color'),
                borderWidth: parseFloat(cssVar('--ma-legend-border-width')),
                borderRadius: parseFloat(cssVar('--ma-legend-border-radius')),
                itemStyle: {
                    color: cssVar('--ma-legend-item-color'),
                    fontSize: cssVar('--ma-legend-item-font-size'),
                    fontWeight: cssVar('--ma-legend-item-font-weight'),
                },
                itemHoverStyle: {
                    color: cssVar('--ma-legend-item-hover-color'),
                },
                itemHiddenStyle: {
                    color: cssVar('--ma-legend-item-hidden-color'),
                },
            }
            switch (data.legendPosition) {
                case 'Top':
                    return {
                        ...common,
                        verticalAlign: 'top',
                    };
                case 'Bottom':
                    return {
                        ...common,
                        verticalAlign: 'bottom',
                    };
                case 'Right':
                    return {
                        ...common,
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle',
                        itemMarginTop: 4,
                        itemMarginBottom: 4,
                    }
                case 'Left':
                    return {
                        ...common,
                        layout: 'vertical',
                        align: 'left',
                        verticalAlign: 'middle',
                        itemMarginTop: 4,
                        itemMarginBottom: 4,
                    }
            }
        }

        const config = {
            chart: {
                animation: false,
                backgroundColor: cssVar('--ma-chart-background-color'),
                borderColor: cssVar('--ma-chart-border-color'),
                borderWidth: parseFloat(cssVar('--ma-chart-border-width')),
                borderRadius: parseFloat(cssVar('--ma-chart-border-radius')),
                plotBackgroundColor: cssVar('--ma-plot-background-color'),
                plotBorderColor: cssVar('--ma-plot-border-color'),
                plotBorderWidth: parseFloat(cssVar('--ma-plot-border-width')),
                zoomType: 'y',
                ignoreHiddenSeries: true,
                style: {
                    fontFamily: cssVar('--ma-chart-font-family'),
                    fontSize: cssVar('--ma-chart-font-size'),
                },
                resetZoomButton: {
                    theme: {
                        fill: cssVar('--ma-reset-zoom-fill-color'),
                        stroke: cssVar('--ma-reset-zoom-stroke-color'),
                        strokeWidth: parseFloat(cssVar('--ma-reset-zoom-stroke-width')),
                        r: parseFloat(cssVar('--ma-reset-zoom-border-radius')),
                        style: {
                            fontFamily: cssVar('--ma-reset-zoom-font-family'),
                            fontSize: cssVar('--ma-reset-zoom-font-size'),
                            fontWeight: cssVar('--ma-reset-zoom-font-weight'),
                            color: cssVar('--ma-reset-zoom-color'),
                        },
                        states: {
                            hover: {
                                fill: cssVar('--ma-reset-zoom-hover-fill-color'),
                                stroke: cssVar('--ma-reset-zoom-hover-stroke-color'),
                                style: {
                                    color: cssVar('--ma-reset-zoom-hover-color'),
                                    cursor: 'pointer',
                                }
                            },
                        },
                    }
                }
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
            },
            legend: getLegend(data),
            xAxis: {
                type: {Log: 'logarithmic', Lin: 'linear'}[xAxisData.type],
                min: xAxisData.min === undefined ? null : xAxisData.min,
                max: xAxisData.max === undefined ? null : xAxisData.max,
                gridLineWidth: 1,
                gridLineColor: cssVar('--ma-axis-grid-line-color'),
                minorGridLineColor: cssVar('--ma-axis-minor-grid-line-color'),
                minorTickInterval: "auto",
                tickColor: cssVar('--ma-axis-tick-color'),
                minorTickColor: cssVar('--ma-axis-minor-tick-color'),
                lineColor: cssVar('--ma-axis-line-color'),
                title: {
                    text: xAxisData.title,
                    style: {
                        fontWeight: cssVar('--ma-axis-title-font-weight'),
                        fontSize: cssVar('--ma-axis-title-font-size'),
                        color: cssVar('--ma-axis-title-color'),
                    },
                },
                labels: {
                    style: {
                        fontWeight: cssVar('--ma-axis-label-font-weight'),
                        fontSize: cssVar('--ma-axis-label-font-size'),
                        color: cssVar('--ma-axis-label-color'),
                    },
                },
            },
            yAxis: {
                type: {Log: 'logarithmic', Lin: 'linear'}[yAxisData.type],
                min: yAxisData.min === undefined ? null : yAxisData.min,
                max: yAxisData.max === undefined ? null : yAxisData.max,
                gridLineWidth: 1,
                gridLineColor: cssVar('--ma-axis-grid-line-color'),
                minorGridLineColor: cssVar('--ma-axis-minor-grid-line-color'),
                minorTickInterval: "auto",
                tickColor: cssVar('--ma-axis-tick-color'),
                minorTickColor: cssVar('--ma-axis-minor-tick-color'),
                lineColor: cssVar('--ma-axis-line-color'),
                title: {
                    text: yAxisData.title,
                    style: {
                        fontWeight: cssVar('--ma-axis-title-font-weight'),
                        fontSize: cssVar('--ma-axis-title-font-size'),
                        color: cssVar('--ma-axis-title-color'),
                    },
                },
                labels: {
                    style: {
                        fontWeight: cssVar('--ma-axis-label-font-weight'),
                        fontSize: cssVar('--ma-axis-label-font-size'),
                        color: cssVar('--ma-axis-label-color'),
                    },
                },
            },
            tooltip: {
                positioner: (labelWidth, labelHeight, point) => {
                    const { plotLeft, plotTop, chartBackground } = this._chart;
                    let x = point.plotX + plotLeft - labelWidth/2;
                    let y = point.plotY + plotTop + 14;
                    x = Math.max(0, x);
                    x = Math.min(chartBackground.width - labelWidth - 2, x);
                    return {x, y};
                },
                style: {
                    color: cssVar('--ma-tooltip-color'),
                    fontSize: cssVar('--ma-tooltip-font-size'),
                    fontWeight: cssVar('--ma-tooltip-font-weight'),
                    padding: parseFloat(cssVar('--ma-tooltip-padding')),
                },
            },
            plotOptions: {
                scatter: {
                    cursor: 'pointer',
                },
            },
            series: [...plottableSeries.map(series => {
                switch (series.type) {
                    case 'Calculated': return calcCurveSeries(series);
                    case 'FitLine': return fitLineSeries(series);
                    case 'InputPoints': return inputPointsSeries(series);
                    case 'Kinetic': return kineticSeries(series);
                    default: return kineticMaxSlopeSeries(series);
                }
            }), getFlagSeries()],
        };

        this._state.set({chartData: config});
    }

    _renderFlags() {
        Object.keys(this._seriesData).forEach(seriesId => {
            const series = this._chart.get(seriesId);
            series && series.data && series.data.filter(this._getFlagState).forEach(point => {
                this._toggleFlagPoint(series, point, series.visible);
            });
            series && series.update({
                enableMouseTracking: series.visible,
            });
        });
    }

    _onSeriesVisibilityChange = evt => {
        const seriesId = evt.target.options.id;
        const series = this._chart.get(seriesId);
        series.data.filter(this._getFlagState).forEach(point => {
            this._toggleFlagPoint(series, point, series.visible);
        });
        series.update({
            enableMouseTracking: series.visible,
        });
    }

    _onFlaggablePointClick = evt => {
        if (!this._state.get('flaggingEnabled')) {
            return;
        }

        let { point } = evt;
        let { series } = point;
        let pointId = point.options.id;
        const isFlag = series.name === 'Flags';
        if (isFlag) {
            pointId = pointId.replace(/^Flags-/, '');
            point = this._chart.get(pointId);
            series = this._chart.get(this._pointData[pointId].seriesId);
        }
        const newFlagState = !this._getFlagState(point);
        this._setFlagState(series, point, newFlagState);
        const event = new CustomEvent('flagchange', {
            bubbles: true,
            detail: {
                ...this._pointData[pointId].detail,
                flagState: newFlagState,
            }
        });
        this.dispatchEvent(event);
    }

    _getFlagState = point => {
        const data = this._pointData[point.options.id || point.id];
        return !!data && data.flagState;
    }

    _setFlagState(series, point, newFlagState) {
        const data = this._pointData[point.options.id || point.id];
        if (data) {
            data.flagState = newFlagState;
            if (series.visible) {
                this._toggleFlagPoint(series, point, newFlagState);
            }
        }
    }

    _toggleFlagPoint(series, point, newFlagState) {
        const pointId = point.options.id || point.id;
        const flagSeries = this._chart.get('Flags');
        const flagId = `Flags-${pointId}`;
        const flagPoint = this._chart.get(flagId);
        if (!newFlagState && flagPoint) {
            const index = flagSeries.data.indexOf(flagPoint);
            flagSeries.removePoint(index);
        } else if (newFlagState && !flagPoint) {
            const data = [
                ...flagSeries.data.map(pointObject => ({
                    marker: {
                        enabled: true,
                    },
                    id: pointObject.id,
                    name: pointObject.options.name,
                    x: pointObject.x,
                    y: pointObject.y,
                })),
                {
                    marker: {
                        enabled: true,
                    },
                    id: flagId,
                    name: point.options.name,
                    x: point.x,
                    y: point.y,
                }
            ];
            data.sort((a, b) => {
                if (a.y > b.y) {
                    return 1;
                } else if (a.y < b.y) {
                    return -1;
                } else if (a.x > b.x) {
                    return 1;
                } else if (a.x < b.x) {
                    return -1;
                } else {
                    return 0;
                }
            });
            flagSeries.setData(data);
        }
    }

    setFlagState(state, seriesName, arg1, arg2) {
        if (typeof state !== 'boolean') throw new Error(`invalid arguments`);
        if (typeof seriesName !== 'string') throw new Error(`invalid arguments`);
        const series = this._chart.series.find(series => series.name === seriesName);
        if (!series) throw new Error(`series [${seriesName}] not found`);
        let id, x, y, point;
        if (arg2 !== undefined) {
            if (typeof arg1 !== 'number' || typeof arg2 !== 'number') throw new Error(`invalid arguments`);
            y = arg2;
            x = arg1;
            point = series.data.find(pointObject => pointObject.x === x && pointObject.y === y);
        } else if (arg1 !== undefined) {
            if (typeof arg1 !== 'string' && typeof arg1 !== 'number') throw new Error(`invalid arguments`);
            id = arg1;
            point = this._chart.get(`${series.options.id}-${id}`);
        } else {
            throw new Error(`invalid arguments`);
        }
        if (point) {
            this._setFlagState(series, point, state);
        } else {
            throw new Error('point not found');
        }
    }

    getFlagged() {
        const flaggedPoints = [];
        Object.keys(this._pointData).forEach(id => {
            const data = this._pointData[id];
            if (data.flagState) {
                flaggedPoints.push({...data.detail});
            }
        });
        return flaggedPoints;
    }

    setFlagged(dataJson) {
        this.removeAllFlags();
        Object.keys(this._pointData).forEach(id => {
            const data = this._pointData[id];
            const detail = data.detail;
            if (!!dataJson.find(item => {
                if (item.pointId !== detail.pointId) return false;
                if (item.seriesName !== detail.seriesName) return false;
                if (item.label !== detail.label) return false;
                if (item.x !== detail.x) return false;
                if (item.y !== detail.y) return false;
                if (item.containerIndex !== detail.containerIndex) return false;
                if (item.matrixIndex !== detail.matrixIndex) return false;
                return true;
            })) {
                this.setFlagState(true, detail.seriesName, detail.pointId);
            }
        });
    }

    removeAllFlags() {
        Object.keys(this._pointData).forEach(id => {
            const data = this._pointData[id];
            data.flagState = false;
        });
        this._chart.get('Flags').setData([]);
    }
}
