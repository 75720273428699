import MyassaysInteractiveChart from './MyassaysInteractiveChart';

customElements.define('myassays-interactive-chart', MyassaysInteractiveChart);

document.write(`
<script src="https://code.highcharts.com/4.2.5/highcharts.js" defer></script>
<script src="https://code.highcharts.com/4.2.5/highcharts-more.js" defer></script>
<style>
    myassays-interactive-chart {
        --ma-chart-font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif;
        --ma-chart-font-size: 12px;
        --ma-chart-color: #000000;
        --ma-chart-background-color: #FFFFFF;
        --ma-chart-border-width: 0;
        --ma-chart-border-color: #4572A7;
        --ma-chart-border-radius: 0;
        --ma-plot-background-color: transparent;
        --ma-plot-border-width: 0;
        --ma-plot-border-color: #C0C0C0;
        --ma-legend-background-color: transparent;
        --ma-legend-border-width: 0;
        --ma-legend-border-color: #909090;
        --ma-legend-border-radius: 0;
        --ma-legend-color: var(--ma-chart-color);
        --ma-legend-font-weight: normal;
        --ma-legend-item-color: #333333;
        --ma-legend-item-hover-color: #000000;
        --ma-legend-item-hidden-color: #CCCCCC;
        --ma-legend-item-font-size: var(--ma-chart-font-size);
        --ma-legend-item-font-weight: normal;
        --ma-axis-line-color: #C0D0E0;
        --ma-axis-grid-line-color: var(--ma-axis-line-color);
        --ma-axis-tick-color: var(--ma-axis-line-color);
        --ma-axis-minor-grid-line-color: #e8e8e8;
        --ma-axis-minor-tick-color: var(--ma-axis-line-color);
        --ma-axis-title-color: var(--ma-chart-color);
        --ma-axis-title-font-size: var(--ma-chart-font-size);
        --ma-axis-title-font-weight: normal;
        --ma-axis-label-color: var(--ma-chart-color);
        --ma-axis-label-font-size: var(--ma-chart-font-size);
        --ma-axis-label-font-weight: normal;
        --ma-tooltip-padding: 8px;
        --ma-tooltip-color: #333333;
        --ma-tooltip-font-size: var(--ma-chart-font-size);
        --ma-tooltip-font-weight: normal;
        --ma-reset-zoom-fill-color: lightgray;
        --ma-reset-zoom-stroke-color: black;
        --ma-reset-zoom-stroke-width: 1;
        --ma-reset-zoom-border-radius: 4;
        --ma-reset-zoom-color: black;
        --ma-reset-zoom-font-family: var(--ma-chart-font-family);
        --ma-reset-zoom-font-size: var(--ma-chart-font-size);
        --ma-reset-zoom-font-weight: normal;
        --ma-reset-zoom-hover-fill-color: white;
        --ma-reset-zoom-hover-stroke-color: var(--ma-reset-zoom-stroke-color);
        --ma-reset-zoom-hover-color: var(--ma-reset-zoom-color);
    }
</style>
`);

if (window.MyAssaysInteractiveChart !== undefined) {
    if (!(window.MyAssaysInteractiveChart instanceof Object)) {
        throw new Error('Could not create "window.MyAssaysInteractiveChart" namespace.');
    }
} else {
    window.MyassaysInteractiveChart = {};
}
window.MyAssaysInteractiveChart = {
    ...window.MyAssaysInteractiveChart,
    getById: function (id) {
        const elem = document.getElementById(id);
        if (!elem) {
            throw `An instance of MyAssaysInteractiveChart with the id "${id}" could not be found.`;
        } else if (! elem instanceof MyassaysInteractiveChart) {
            throw `The element with the id "${id}" is not a MyassaysInteractiveChart.`;
        } else {
            return elem;
        }
    },
    populateFromJson: function (id, dataJson) {
        this.getById(id).populateFromJson(dataJson);
    },
    populateFromAnalysisControlXml: function (id, dataXml) {
        this.getById(id).populateFromAnalysisControlXml(dataXml);
    },
    getFlagged: function (id) {
        return this.getById(id).getFlagged();
    },
    removeAllFlags: function (id) {
        this.getById(id).removeAllFlags();
    },
    setFlagState: function (id, state, seriesName, arg1, arg2) {
        this.getById(id).setFlagState(state, seriesName, arg1, arg2);
    },
    setFlagged: function (id, dataJson) {
        this.getById(id).setFlagged(dataJson);
    }
}
